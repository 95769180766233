import React, { Fragment } from "react";
import { Link } from "@reach/router";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "../App/styles";

interface NavigationItemProps extends ListItemProps {
  label: string;
  to: string;
}

const NavigationItem: React.FunctionComponent<
  WithStyles<typeof styles> & NavigationItemProps
> = ({ children, label, classes, to }) => (
  <ListItem to={to} component={Link as any} className={classes.navItem}>
    <ListItemIcon className={classes.navIcon}>
      <Fragment>{children}</Fragment>
    </ListItemIcon>
    <ListItemText>{label}</ListItemText>
  </ListItem>
);

NavigationItem.defaultProps = {
  button: true
};

export default withStyles(styles)(NavigationItem);

import * as React from "react";
import { Redirect } from "@reach/router";
import { useMutation } from "@apollo/react-hooks";
import { parse } from "query-string";
import { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { loader } from "graphql.macro";
const LOGIN = loader("./login.graphql");
const GET_USER = loader("../../queries/getUser.graphql");

const Auth: React.FunctionComponent = () => {
  const { code } = parse(window.location.search);

  const [handleLogin, { data, error, loading }] = useMutation(LOGIN, {
    variables: { code },
    update(cache, { data: { user } }) {
      cache.writeQuery({
        query: GET_USER,
        data: { user }
      });
    }
  });

  useEffect(() => {
    handleLogin({ variables: { code } });
  }, [code, handleLogin]);

  if (error) {
    console.error(error);
    return <pre>{JSON.stringify(error)}</pre>;
  }

  if (loading || !data) {
    return <CircularProgress />;
  }

  if (data.login.jwt) {
    window.localStorage.setItem("planetToken", data.login.jwt);
  }

  return <Redirect to="/account" noThrow />;
};

export default Auth;

import { CircularProgress } from "@material-ui/core";
import { RouteComponentProps, Router } from "@reach/router";
import React, { FunctionComponent, lazy, Suspense } from "react";

import Auth from "../Auth";
import AppLoading from "./AppLoading";

const Planet = lazy(() => import("../../pages/Planet"));
const Planets = lazy(() => import("../../pages/Planets"));
const Account = lazy(() => import("../../pages/Account"));
const Calendar = lazy(() => import("../../pages/Calendar"));
const Logout = lazy(() => import("../../pages/Account/Logout"));
const Discord = lazy(() => import("../../pages/Discord"));

const Route: FunctionComponent<{
  component: FunctionComponent;
} & RouteComponentProps> = ({ component: Component, ...rest }) => (
  <Component {...rest} />
);

const RouterSuspense: FunctionComponent<RouteComponentProps> = ({
  children
}) => <Suspense fallback={<CircularProgress />}>{children}</Suspense>;

const Routes = () => (
  <Router>
    <RouterSuspense default>
      <Route path="/planet/:characterId/:planetId" component={Planet} />
      <Route path="/planets" component={Planets} />
      <Route path="/account" component={Account} />
      <Route path="/calendar" component={Calendar} />
      <Route path="/discord" component={Discord} />
      <Route path="/oauth/callback" component={Auth} />
      <Route path="/oauth/callback/add-character" component={Auth} />
      <Route path="/logout" component={Logout} />
      <Route default component={AppLoading} />
    </RouterSuspense>
  </Router>
);

export default Routes;

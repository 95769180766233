import React, { Fragment } from "react";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "../App/styles";

interface ExternalNavigationItemProps extends ListItemProps {
  label: string;
  href: string;
}

const ExternalNavigationItem: React.FunctionComponent<
  WithStyles<typeof styles> & ExternalNavigationItemProps
> = ({ children, label, classes, href }) => (
  <ListItem href={href} button component="a" className={classes.navItem}>
    <ListItemIcon className={classes.navIcon}>
      <Fragment>{children}</Fragment>
    </ListItemIcon>
    <ListItemText primary={label} />
  </ListItem>
);

export default withStyles(styles)(ExternalNavigationItem);

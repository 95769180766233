/* eslint-disable react/jsx-filename-extension */
import { ApolloProvider } from "@apollo/react-hooks";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { LocationProvider } from "@reach/router";
import ApolloClient from "apollo-boost";
import React, { useMemo, useState } from "react";
import ReactDOM from "react-dom";

import App from "./components/App";
import * as serviceWorker from "./utils/serviceWorker";

const client = new ApolloClient({
  uri: `/api/`,
  request: operation => {
    const token = window.localStorage.getItem("planetToken");
    if (token) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`
        }
      });
    }
  }
});

const Root = () => {
  const [darkMode, toggleDarkMode] = useState(true);

  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: darkMode ? "dark" : "light"
        },
        overrides: {
          MuiLink: {
            root: {
              color: darkMode ? "#a7b4f9" : "primary"
            }
          }
        }
      }),
    [darkMode]
  );

  return (
    <LocationProvider>
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <App toggleDarkMode={toggleDarkMode} />
        </MuiThemeProvider>
      </ApolloProvider>
    </LocationProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
// serviceWorker.register();

// if (module.hot) {
//   module.hot.accept('./App', () => {
//     ReactDOM.render(<Root />, document.getElementById('root'));
//   });
// }
